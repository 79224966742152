//
// Adds the ability to navigate between months in the WooCommerce Bookings table.
//

function floorDate(sourceDate, span) {
  let targetDate = new Date(sourceDate)

  if (span === 'month') {
    targetDate.setHours(0, 0, 0, 0)
    targetDate.setDate(1)
  } else if (span === 'quarter') {
    targetDate.setHours(0, 0, 0, 0)
    targetDate.setDate(1)
    targetDate.setMonth(Math.floor(targetDate.getMonth() / 3) * 3)
  }

  return targetDate
}

function shiftDate(sourceDate, span, steps) {
  let targetDate = new Date(sourceDate)

  if (span === 'month') {
    targetDate.setMonth(targetDate.getMonth() + steps)
  } else if (span === 'quarter') {
    targetDate.setMonth(targetDate.getMonth() + steps * 3)
  }

  return targetDate
}

function getSlotDates(wrapperElement) {
  const dates = []
  const rowElements = wrapperElement.querySelectorAll(
    'table.woocommerce-bookings-table tbody > tr[data-slot-timestamp]'
  )

  for (const rowElement of rowElements) {
    const timestamp = parseInt(rowElement.getAttribute('data-slot-timestamp'))
    if (isNaN(timestamp)) continue

    const date = new Date(timestamp * 1000)
    if (isNaN(date)) continue

    dates.push(date)
  }

  return dates.sort((a, b) => a - b)
}

function setFromDate(wrapperElement, fromDate) {
  const span = wrapperElement.getAttribute('data-woocommerce-bookings-table-span')
  if (!span) return

  const dates = getSlotDates(wrapperElement)
  if (!dates) return

  let toDate = shiftDate(fromDate, span, 1)
  toDate.setMilliseconds(toDate.getMilliseconds() - 1)
  wrapperElement.setAttribute('data-woocommerce-bookings-table-from-date', fromDate.getTime().toString())

  const navigationElement = wrapperElement.querySelector('.woocommerce-bookings-table-nav')
  if (navigationElement) {
    const nowElement = navigationElement.querySelector('.woocommerce-bookings-table-nav__now')
    if (nowElement) {
      if (span === 'month') {
        const formatter = new Intl.DateTimeFormat('en-GB', { month: 'long', year: 'numeric' })
        nowElement.innerText = formatter.format(fromDate)
      } else if (span === 'quarter') {
        const fromFormatter = new Intl.DateTimeFormat('en-GB', { month: 'short' })
        const toFormatter = new Intl.DateTimeFormat('en-GB', { month: 'short', year: 'numeric' })
        nowElement.innerText = fromFormatter.format(fromDate) + ' - ' + toFormatter.format(toDate)
      }
    }

    const previousElement = navigationElement.querySelector('.woocommerce-bookings-table-nav__previous')
    const nextToDate = shiftDate(toDate, span, -1)
    if (dates[0] > nextToDate) {
      previousElement.setAttribute('disabled', 'disabled')
    } else {
      previousElement.removeAttribute('disabled')
    }
  }

  const tableElement = wrapperElement.querySelector('.woocommerce-bookings-table')
  if (tableElement) {
    const rowElements = tableElement.querySelectorAll('tbody > tr[data-slot-timestamp]')
    for (const rowElement of rowElements) {
      const timestamp = parseInt(rowElement.getAttribute('data-slot-timestamp'))
      const date = new Date(timestamp * 1000)
      console.log(date)
      if (isNaN(date) || date < fromDate || date > toDate) {
        rowElement.setAttribute('hidden', 'hidden')
      } else {
        rowElement.removeAttribute('hidden')
      }
    }

    const noDatesRowElement = tableElement.querySelector('tbody > tr[data-error="no-dates"]')
    if (noDatesRowElement) {
      const visibleRowElements = tableElement.querySelectorAll('tbody > tr:not([data-error]):not([hidden])')
      if (visibleRowElements.length > 0) {
        noDatesRowElement.setAttribute('hidden', 'hidden')
      } else {
        noDatesRowElement.removeAttribute('hidden')
      }
    }
  }
}

function resetFromDate(wrapperElement) {
  const fromDate = new Date(parseInt(wrapperElement.getAttribute('data-woocommerce-bookings-table-default-from-date')))
  if (isNaN(fromDate)) return

  setFromDate(wrapperElement, fromDate)
}

function stepFromDate(wrapperElement, steps) {
  const span = wrapperElement.getAttribute('data-woocommerce-bookings-table-span')
  if (!span) return

  const currentDate = new Date(parseInt(wrapperElement.getAttribute('data-woocommerce-bookings-table-from-date')))
  if (isNaN(currentDate)) return

  setFromDate(wrapperElement, shiftDate(currentDate, span, steps))
}

function stepForwards(wrapperElement) {
  stepFromDate(wrapperElement, 1)
}

function stepBackwards(wrapperElement) {
  stepFromDate(wrapperElement, -1)
}

document.addEventListener('DOMContentLoaded', (_event) => {
  const wrapperElements = document.querySelectorAll('.woocommerce-bookings-table-wrap')
  for (const wrapperElement of wrapperElements) {
    const navigationElement = wrapperElement.querySelector('.woocommerce-bookings-table-nav')
    if (!navigationElement) continue

    const span = wrapperElement.getAttribute('data-woocommerce-bookings-table-span')
    if (!span) continue

    const dates = getSlotDates(wrapperElement)
    if (!dates || dates.length < 1) continue

    let fromDate = dates[0]

    if (URL) {
      const currentURL = new URL(window.location.href)
      const eventsFromInURL = currentURL.searchParams.get('events-from')
      if (typeof eventsFromInURL === 'string' && eventsFromInURL.length > 0) {
        const eventsFromDateInURL = Date.parse(eventsFromInURL)
        if (!isNaN(eventsFromDateInURL)) fromDate = eventsFromDateInURL
      }
    }

    fromDate = floorDate(fromDate, span)
    wrapperElement.setAttribute('data-woocommerce-bookings-table-default-from-date', fromDate.getTime().toString())
    navigationElement.removeAttribute('hidden')
    setFromDate(wrapperElement, fromDate)
  }
})

document.addEventListener('click', (event) => {
  if (event.target instanceof Element !== true) return

  const wrapperElement = event.target.closest('.woocommerce-bookings-table-wrap')
  if (!wrapperElement) return

  const nowElement = event.target.closest('.woocommerce-bookings-table-nav__now')
  if (nowElement) {
    event.preventDefault()
    if (!nowElement.hasAttribute('disabled')) resetFromDate(wrapperElement)
  }

  const nextElement = event.target.closest('.woocommerce-bookings-table-nav__next')
  if (nextElement) {
    event.preventDefault()
    if (!nextElement.hasAttribute('disabled')) stepForwards(wrapperElement)
  }

  const previousElement = event.target.closest('.woocommerce-bookings-table-nav__previous')
  if (previousElement) {
    event.preventDefault()
    if (!previousElement.hasAttribute('disabled')) stepBackwards(wrapperElement)
  }
})
